<style lang="scss" scoped>
.table-header {
  border: 1px solid #ebeef5;
  border-bottom: none;
  height: 50px;
}
.refresh {
  background-color: #f5f5f5;
  width: 50px;
  &:hover .icon_refresh {
    color: rgba(0, 0, 0, 0.6);
  }
}
.icon_refresh {
  color: #999;
  line-height: 50px;
}
.operation {
  white-space: nowrap;
}
.el-tag {
  margin-left: 10px;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<template>
  <div class="account height_100 flex_tb">
    <!-- 條件刪選 -->
    <collapse @seach="state.seach" />
    <div class="width_100 table-header sizing flex_lr_m">
      <div>
        <i class="el-icon-s-grid pad20" style="color: #999"></i>
        <el-button type="primary" size="mini" @click="export_cli"
          >導出表格</el-button
        >
        <el-button
          type="danger"
          size="mini"
          :disabled="!state.delAccountId.length"
          @click="dels_confirm"
          >批量刪除</el-button
        >
      </div>
      <div class="refresh sizing height_100 flex_c_m" @click="refresh">
        <i class="el-icon-refresh cursor fonts-22 icon_refresh"></i>
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="state.tableData"
      height="100%"
      border
      style="width: 100%"
      class="flex1"
      id="exportTab"
      :row-class-name="tableRowClassName"
      @selection-change="selection_change"
    >
      <!-- <el-table-column prop="id" label="id" sortable> </el-table-column> -->
      <el-table-column type="expand">
        <template #default="props">
          <div v-for="item in props.row.specs" :key="item.id">
            <p v-if="item.goods_attr_combine.some((item) => item.is_select)">
              {{ item.attr_key_name }}
            </p>
            <el-tag
              :closable="true"
              :disable-transitions="false"
              @close="handleClose(curr.goods_skus_id)"
              v-for="curr in item.goods_attr_combine"
              :key="curr.id"
              v-show="curr.is_select"
            >
              {{ curr.attr_values_name }} (<span style="color: red"
                >${{ curr.attr_values_price }}</span
              >)
            </el-tag>
          </div>
          <span v-if="!props.row.specs.length">無規格</span>
        </template>
      </el-table-column>
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="id" label="商品ID" min-width="80">
      </el-table-column>
      <el-table-column prop="goods_img" label="商品圖片" min-width="80">
        <template #default="scope">
          <el-image
            style="width: 80px; height: 80px"
            :src="scope.row.goods_img"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="goods_name" label="商品名稱" min-width="80">
      </el-table-column>
      <el-table-column prop="sort_name" label="所屬分類" min-width="80">
      </el-table-column>
      <el-table-column prop="look_volume" label="是否熱銷" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.is_hot ? "是" : "否" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="has_specs" label="有無規格" min-width="80">
        <template #default="scope">
          <span>{{ scope.row.has_specs ? "有" : "無" }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="price" label="價格" min-width="80">
      </el-table-column>
      <el-table-column prop="look_volume" label="瀏覽量" min-width="80">
      </el-table-column>
      <el-table-column prop="sales_volume" label="銷量" min-width="80">
      </el-table-column>
      <el-table-column prop="goods_stock" label="庫存" min-width="80">
      </el-table-column>
      <el-table-column prop="keysword" label="搜索關鍵字" min-width="80">
      </el-table-column>
      <el-table-column prop="status" label="狀態">
        <template #default="scope">
          <span>{{ scope.row.status ? "正常" : "禁用" }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="member_name"
        label="操作"
        min-width="180px"
        fixed="right"
      >
        <template #default="scope">
          <div class="operation height_100 flex_c_m" style="flex-wrap: wrap">
            <el-button type="primary" size="mini" @click="lookInfo(scope.row)"
              >編輯</el-button
            >
            <el-button type="danger" size="mini" @click="deleteInfo(scope.row)"
              >刪除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分頁 -->
    <div class="flex_m_r pain pad20">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="state.user.page"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="Number(state.count)"
      >
      </el-pagination>
    </div>
    <!-- 彈窗 -->
    <pop-up
      :dialogVisible="state.dialogVisible"
      :userId="'' + state.userId"
      :popData="state.popData"
      @toggleDialogVisible="toggleDialogVisible"
      @reloadData="reloadData"
    />
    <!-- 提示框 -->
    <el-dialog title="提示" v-model="state.dialog" width="400px">
      <span>確定刪除嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialog = false">取 消</el-button>
          <el-button type="primary" @click="dels_confirm">確 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import Collapse from "./components/collapse";
import PopUp from "./components/pop-up";
import FileSaver from "file-saver";
import XLSX from "xlsx";

export default {
  components: {
    Collapse,
    PopUp,
  },
  setup() {
    const req_table_data = () => {
      $http.getGoodsList(state.user).then((res) => {
        console.log(res);
        if (res.status !== 200) return;
        state.tableData = res.data.data;
        state.count = res.data.count;
      });
    };

    const state = reactive({
      tableData: [], // 表格数据
      user: {
        // 请求参数
        limit: 10,
        page: 1,
        goods_name: "",
        sort_id: "",
        status: "",
        time: "",
      },
      count: "", // 總條數
      seach(data) {
        console.log(data);
        // 查询里面的数据
        state.user.goods_name = data.seatch_name;
        state.user.sort_id = data.sort.length
          ? data.sort[data.sort.length - 1]
          : "";
        state.user.status = data.region;
        state.user.time = data.time;
        req_table_data();
      },
      dialogVisible: false, // 弹窗开关
      dialog: false, // 提示框开关
      delAccountId: [], // 批量刪除的id
      delUserId: [], // 单个删除的id
      userId: "", // 用户id
      popData: {}, // 编辑商品时的数据
    });

    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $http, $message, $loading } = proxy;

    // init
    req_table_data();

    // 分頁組件改變页数
    const handleCurrentChange = (val) => {
      state.user.page = val;
      req_table_data();
    };

    // 分頁組件改變每頁顯示条数
    const handleSizeChange = (val) => {
      state.user.limit = val;
      req_table_data();
    };

    // 刷新
    const refresh = () => {
      req_table_data();
    };

    // 弹出层的显示开关
    const toggleDialogVisible = () => {
      state.dialogVisible = false;
    };

    // 弹出层点击确定触发
    const reloadData = (data) => {
      console.log(data);
      req_table_data();
    };

    // 选中的id数组
    const selection_change = (selection) => {
      state.delAccountId = selection.map((item) => {
        item = item.id;
        return item;
      });
    };

    // 选中的id数组
    const del_accout = () => {
      state.delUserId = [];
      state.dialog = true;
    };

    // 确定弹出层点击确定
    const dels_confirm = async () => {
      const res = await $http.delGoods({
        id: state.delUserId.length === 0 ? state.delAccountId : state.delUserId,
      });
      if (res.status !== 200) return;
      req_table_data();
      $message.success("刪除成功");
      state.dialog = false;
    };

    // 刪除用戶
    const deleteInfo = (data) => {
      console.log(data);
      state.delUserId = [data.id];
      state.dialog = true;
    };

    // 查看数据列表
    const lookInfo = (data) => {
      const loading = $loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      state.popData = data;
      setTimeout(() => {
        loading.close();
        state.dialogVisible = true;
      }, 1000);
    };

    const handleClose = async (id) => {
      const res = await $http.delGoodsSelectSkus({
        goods_skus_id: id,
      });
      if (res.status !== 200) return;
      $message.success("刪除成功");
      req_table_data();
    };

    const export_cli = () => {
      var xlsxParam = { raw: true }; // 导出的内容只做解析，不进行格式转换
      var wb = XLSX.utils.table_to_book(
        document.querySelector("#exportTab"),
        xlsxParam
      );

      /* get binary string as output */
      var wbout = XLSX.write(wb, {
        bookType: "xlsx",
        bookSST: true,
        type: "array",
      });
      try {
        FileSaver.saveAs(
          new Blob([wbout], { type: "application/octet-stream" }),
          "商品列表.xlsx"
        );
        $message.success("導出成功");
      } catch (e) {
        if (typeof console !== "undefined") {
          console.log(e, wbout);
        }
      }
      return wbout;
    };

    const tableRowClassName = ({ row, rowIndex }) => {
      if (row.is_min_stock) {
        return "warning-row";
      }
      return "";
    };

    return {
      state,
      handleCurrentChange,
      handleSizeChange,
      refresh,
      toggleDialogVisible,
      reloadData,
      selection_change,
      del_accout,
      dels_confirm,
      deleteInfo,
      lookInfo,
      handleClose,
      export_cli,
      tableRowClassName,
    };
  },
};
</script>
